import React, {useEffect} from 'react';
import {nanoid} from 'nanoid';
import {useDispatch, useSelector} from 'react-redux';
import {Box, styled} from '@mui/material';
import CustomLoader from '../../common/CustomLoader/CustomLoader';
import {
  THANK_YOU_PAGE_LAYOUT_CUSTOM,
  THANK_YOU_PAGE_LAYOUT_DEFAULT,
  THANK_YOU_PAGE_LAYOUT_REDIRECT
} from '../../helpers/constant/formSettingsConstant';
import {S3_SIGNED_URL_ACTION} from '../../helpers/constant/formSubmissionConstant';
import {getPublicPageFiles} from '../../helpers/utils/getPublicPageFiles';
import getPublicPageSubmitContent from '../../helpers/utils/getPublicPageSubmitContent';
import PublicPageService from '../../services/PublicPage.service';
import {submitPublicPage} from '../../state/features/publicPage/publicPageSlice';
import ThankYouPageDesign from '../Setting/ThankYouPage/ThankYouPageDesign';

const CustomBox = styled(Box)(({theme}) => ({
  width: '100%',
  borderRadius: '8px',
  position: 'relative',
  textAlign: 'center',
  '& img': {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain'
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem'
  }
}));

const PublicPageSubmit = ({fields, editMode}) => {
  const dispatch = useDispatch();

  const thankyouPageData = useSelector(state =>
    JSON.stringify(state.formThankYou.data) === '{}'
      ? state.publicPage.thankyouPageData
      : state.formThankYou.data
  );
  const {isSubmitting, isSubmited, agencyId, apiKey} = useSelector(state => state.publicPage);

  useEffect(() => {
    if (!editMode) {
      handleSubmitForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      (isSubmited || editMode) &&
      thankyouPageData.type === THANK_YOU_PAGE_LAYOUT_REDIRECT &&
      thankyouPageData.shortURL
    ) {
      window.open(
        thankyouPageData.shortURL,
        thankyouPageData.urlWindow !== '_blank' ? '_top' : '_blank'
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmited]);

  const handleSubmitForm = async () => {
    //Uploading files
    let files = getPublicPageFiles(fields),
      fileIds = [];

    if (files.length > 0) {
      //Generating file ids
      //Appending unique id with the name.
      fileIds = files.map(file => {
        let extPosition = file.name.lastIndexOf('.');
        if (extPosition === -1) {
          return file.name;
        }

        return (
          file.name.substring(0, extPosition) + '-' + nanoid() + file.name.substring(extPosition)
        );
      });

      let signedUrls = await PublicPageService.getS3SignedUrls({
        fileId: fileIds,
        action: S3_SIGNED_URL_ACTION.UPLOAD,
        formApiKey: apiKey,
        agencyId: Number(agencyId)
      });

      if (signedUrls.responseCode === 200 && signedUrls.status === true) {
        let buckedUrls = JSON.parse(signedUrls['bucketUrl']);
        let promisses = buckedUrls.map((url, index) =>
          PublicPageService.uploadIndividualS3Files(url.bucketUrl, files[index], {
            'content-type': files[index].type
          })
        );

        await Promise.all(promisses);
      }
    }

    let submitData = getPublicPageSubmitContent(fields, fileIds);
    dispatch(submitPublicPage(submitData));
  };

  if (isSubmitting && !editMode) {
    return <CustomLoader text="Please wait while your form is being submitted." />;
  }

  if (
    thankyouPageData.type === undefined ||
    thankyouPageData.type === THANK_YOU_PAGE_LAYOUT_DEFAULT ||
    thankyouPageData.type === THANK_YOU_PAGE_LAYOUT_REDIRECT
  ) {
    return <ThankYouPageDesign />;
  }

  if (thankyouPageData.type === THANK_YOU_PAGE_LAYOUT_CUSTOM) {
    return <CustomBox dangerouslySetInnerHTML={{__html: thankyouPageData.message}} />;
  }
};

export default PublicPageSubmit;
