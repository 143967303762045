import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {format, parse as parseTime} from 'date-fns';
import Box from '@mui/material/Box';
import {FormControl, Grid, MenuItem, Select, styled, Typography} from '@mui/material';
import FormSettingsLoader from '../LoadingView/FormSettingsLoader';
import CloseAlert from '../../common/Alert/CloseAlert';
import CoreTextField from '../../common/TextField/CoreTextField';
import CustomTimePicker from '../../common/TimePicker/CustomTimePicker';
import CustomDatePicker from '../../common/PrevDatePicker/CustomDatePicker';
import {
  FORM_SETTINGS_FORM_STATUS,
  FORM_SETTINGS_FORM_STATUS_LIST,
  FORM_SETTINGS_NOTIFICATION_SETTINGS_LIST
} from '../../helpers/constant/formSettingsConstant';
import removeFalsyObjectProperty from '../../helpers/utils/removeFalsyObjectProperty';
import {
  selectCampaignList,
  selectGetCampaignSuccess
} from '../../state/features/campaign/campaignSelector';
import {
  selectGetPipelineSuccess,
  selectPipelineList
} from '../../state/features/pipeline/pipelineSelector';
import {
  getFormSetting,
  resetFormSettingState,
  updateFormSetting
} from '../../state/features/formSetting/formSettingSlice';
import {getCampaignData} from '../../state/features/campaign/campaignSlice';
import {getPipelineData} from '../../state/features/pipeline/pipelineSlice';
import {getPipelineStageData} from '../../state/features/pipelineStage/pipelineStageSlice';
import {selectPipelineStageList} from '../../state/features/pipelineStage/pipelineStageSelector';
import {selectFormSettingsState} from '../../state/features/formSetting/formSettingsSelector';
import {selectBuilderFormTitle} from '../../state/features/builderSetting/builderSettingSelector';
import {renameBuilderFormTitle} from '../../state/features/builderSetting/builderSettingSlice';

const StatusViewBox = styled(Box)(({theme}) => ({
  background: theme.palette.background.default,
  padding: theme.spacing(3),
  marginTop: theme.spacing(2)
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const FormsSettingsForm = () => {
  const {formId} = useParams();
  const dispatch = useDispatch();

  const title = useSelector(selectBuilderFormTitle);
  const {data: formSettingData, isLoading} = useSelector(selectFormSettingsState);
  const campaignList = useSelector(selectCampaignList);
  const isGetCampaignSuccess = useSelector(selectGetCampaignSuccess);
  const pipelineList = useSelector(selectPipelineList);
  const isGetPipelineSuccess = useSelector(selectGetPipelineSuccess);
  const pipelineStageList = useSelector(selectPipelineStageList);

  const [formTitle, setFormTitle] = useState(title);
  const [formSettings, setFormSettings] = useState({
    status: FORM_SETTINGS_FORM_STATUS.ENABLED,
    limit: 100,
    date: format(new Date(), 'yyyy-MM-dd'),
    time: '',
    campaignId: '',
    pipelineId: '',
    stageId: '',
    facebookPixelId: '',
    notifySetting: 0
  });

  useEffect(() => {
    if (formSettingData?.id) {
      const data = removeFalsyObjectProperty({
        status: formSettingData.status,
        date: formSettingData.date,
        time: formSettingData.time,
        campaignId: formSettingData.campaignId,
        pipelineId: formSettingData.pipelineId,
        stageId: formSettingData.stageId,
        facebookPixelId: formSettingData.facebookPixelId,
        notifySetting: formSettingData.notifySetting || 0
      });

      // get pipeline list when has pipelineId
      if (data.pipelineId) {
        dispatch(getPipelineStageData({pipelineId: data.pipelineId}));
      }

      setFormSettings(prevState => ({
        ...prevState,
        ...data,
        limit: typeof formSettingData.limit === 'number' ? formSettingData.limit : 100
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSettingData]);

  useEffect(() => {
    dispatch(getFormSetting({formId: formId}));

    return () => dispatch(resetFormSettingState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isGetCampaignSuccess) dispatch(getCampaignData());
    if (!isGetPipelineSuccess) dispatch(getPipelineData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // after change call this function
  const callUpdateFormSetting = (params = {}) => {
    const reqObj = {
      id: formId,
      formSettings: JSON.stringify({...formSettings, ...params})
    };
    dispatch(updateFormSetting(reqObj));
  };

  const onChangeFormTitle = event => {
    setFormTitle(event.target.value);
  };

  const onBlurFormTitle = event => {
    if (event.target.value !== title) {
      dispatch(renameBuilderFormTitle({formId: formId, formTitle: formTitle}));
    }
  };

  const onChangeNumberInput = event => {
    const {name, value} = event.target;
    setFormSettings(prevState => ({...prevState, [name]: value}));
  };

  const onBlurNumberAction = event => {
    const {name, value} = event.target;
    setFormSettings(prevState => ({...prevState, [name]: Number(value) < 0 ? 0 : Number(value)}));

    // when value actually change call the action
    if (formSettingData[name] !== value) {
      callUpdateFormSetting({[name]: Number(value) < 0 ? 0 : Number(value)});
    }
  };

  const handleChangeFormStatus = event => {
    const {name, value} = event.target;

    if (name === 'pipelineId' && value) {
      dispatch(getPipelineStageData({pipelineId: value}));
      setFormSettings(prevState => ({...prevState, [name]: value, stageId: ''}));
      callUpdateFormSetting({[name]: value, stageId: ''});
      return;
    }

    setFormSettings(prevState => ({...prevState, [name]: value}));

    if (formSettingData[name] !== value) {
      callUpdateFormSetting({[name]: value});
    }
  };

  const onChangeDate = date => {
    setFormSettings(prevState => ({...prevState, date: date}));

    if (formSettingData.date !== date) {
      callUpdateFormSetting({date: date});
    }
  };

  const onChangeTime = time => {
    try {
      const formattedDate = parseTime(time, 'hh:mm aa', new Date());
      const formattedDateTime = format(formattedDate, 'HH:mm');

      setFormSettings(prevState => ({...prevState, time: formattedDateTime}));

      if (formSettingData.time !== formattedDateTime) {
        callUpdateFormSetting({time: formattedDateTime});
      }
    } catch (err) {}
  };

  if (isLoading) return <FormSettingsLoader />;
  return (
    <Box sx={{p: 3, height: '100%'}}>
      <Grid container spacing={2} pb={1}>
        <Grid item xs={12}>
          <Typography variant={'body2'} color={'text.secondary'} sx={{mb: 0.5}}>
            From Title
          </Typography>
          <CoreTextField
            fullWidth
            type={'text'}
            size={'small'}
            color={'secondary'}
            placeholder="Form Title"
            name={'title'}
            value={formTitle}
            onChange={onChangeFormTitle}
            onBlur={onBlurFormTitle}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <Typography variant={'body2'} color={'text.secondary'} sx={{mb: 0.5}}>
              Form Status
            </Typography>
            <Select
              size={'small'}
              color={'secondary'}
              inputProps={{'aria-label': 'Without label'}}
              name={'status'}
              value={formSettings.status}
              onChange={handleChangeFormStatus}
            >
              {FORM_SETTINGS_FORM_STATUS_LIST?.map(category => (
                <MenuItem key={category.value} value={category.value}>
                  {category.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {formSettings.status === FORM_SETTINGS_FORM_STATUS.DISABLED && (
            <StatusViewBox>
              <CloseAlert
                size={'small'}
                severity={'warning'}
                title={'This form is currently unavailable!'}
              />
            </StatusViewBox>
          )}

          {formSettings.status === FORM_SETTINGS_FORM_STATUS.DISABLED_ON_DATE && (
            <StatusViewBox>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <CustomDatePicker
                    size="medium"
                    open={true}
                    format={'yyyy MMMM dd'}
                    value={formSettings.date}
                    onChange={onChangeDate}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomTimePicker
                    size="medium"
                    placeholder={'HH:MM PM'}
                    value={
                      formSettings.time ? parseTime(formSettings.time, 'HH:mm', new Date()) : null
                    }
                    onChange={onChangeTime}
                  />
                </Grid>
              </Grid>
            </StatusViewBox>
          )}

          {formSettings.status === FORM_SETTINGS_FORM_STATUS.DISABLED_ON_SUBMISSION_LIMIT && (
            <StatusViewBox>
              <Typography variant={'body2'} color={'text.secondary'} sx={{mb: 0.5}}>
                Submission Limit
              </Typography>

              <CoreTextField
                fullWidth
                size="small"
                color={'secondary'}
                type="number"
                placeholder={'100'}
                name={'limit'}
                value={formSettings.limit}
                onChange={onChangeNumberInput}
                onBlur={onBlurNumberAction}
              />
            </StatusViewBox>
          )}

          {formSettings.status ===
            FORM_SETTINGS_FORM_STATUS.DISABLED_ON_DATE_AND_SUBMISSION_LIMIT && (
            <>
              <StatusViewBox>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <CustomDatePicker
                      size="medium"
                      open={true}
                      format={'yyyy MMMM dd'}
                      value={formSettings.date}
                      onChange={onChangeDate}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CustomTimePicker
                      size="medium"
                      placeholder={'HH:MM PM'}
                      value={
                        formSettings.time ? parseTime(formSettings.time, 'HH:mm', new Date()) : null
                      }
                      onChange={onChangeTime}
                    />
                  </Grid>
                </Grid>
              </StatusViewBox>
              <StatusViewBox>
                <Typography variant={'body2'} color={'text.secondary'} sx={{mb: 0.5}}>
                  Submission Limit
                </Typography>

                <CoreTextField
                  fullWidth
                  size="small"
                  color={'secondary'}
                  type="number"
                  placeholder={'100'}
                  name={'limit'}
                  value={formSettings.limit}
                  onChange={onChangeNumberInput}
                  onBlur={onBlurNumberAction}
                />
              </StatusViewBox>
              <StatusViewBox>
                <CloseAlert
                  size={'small'}
                  severity={'warning'}
                  title={'This form is currently unavailable!'}
                />
              </StatusViewBox>
            </>
          )}
        </Grid>

        <Grid item xs={12} md={12}>
          <FormControl fullWidth>
            <Typography variant={'body2'} color={'text.secondary'} sx={{mb: 0.5}}>
              Link to campaign
            </Typography>
            <Select
              displayEmpty
              size={'small'}
              color={'secondary'}
              inputProps={{'aria-label': 'Without label'}}
              name={'campaignId'}
              value={formSettings.campaignId}
              onChange={handleChangeFormStatus}
              MenuProps={MenuProps}
            >
              <MenuItem value="">None</MenuItem>
              {campaignList?.map(category => (
                <MenuItem key={category.id} value={category.id}>
                  {category.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <Typography variant={'body2'} color={'text.secondary'} sx={{mb: 0.5}}>
              Link to pipeline
            </Typography>
            <Select
              displayEmpty
              size={'small'}
              color={'secondary'}
              inputProps={{'aria-label': 'Without label'}}
              name={'pipelineId'}
              value={formSettings.pipelineId}
              onChange={handleChangeFormStatus}
              MenuProps={MenuProps}
            >
              <MenuItem value="">None</MenuItem>
              {pipelineList?.map(pipeline => (
                <MenuItem key={pipeline.id} value={pipeline.id}>
                  {pipeline.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <Typography variant={'body2'} color={'text.secondary'} sx={{mb: 0.5}}>
              Link to stage
            </Typography>
            <Select
              displayEmpty
              size={'small'}
              color={'secondary'}
              inputProps={{'aria-label': 'Without label'}}
              disabled={!formSettings.pipelineId}
              name={'stageId'}
              value={formSettings.stageId}
              onChange={handleChangeFormStatus}
              MenuProps={MenuProps}
            >
              <MenuItem value="">None</MenuItem>
              {pipelineStageList?.map(pipelineStage => (
                <MenuItem key={pipelineStage.id} value={pipelineStage.id}>
                  {pipelineStage.stage}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <Typography variant={'body2'} color={'text.secondary'} sx={{mb: 0.5}}>
              Notifications
            </Typography>
            <Select
              displayEmpty
              size={'small'}
              color={'secondary'}
              inputProps={{'aria-label': 'Without label'}}
              name={'notifySetting'}
              value={formSettings.notifySetting}
              onChange={handleChangeFormStatus}
              MenuProps={MenuProps}
            >
              {FORM_SETTINGS_NOTIFICATION_SETTINGS_LIST.map((notification, index) => (
                <MenuItem key={notification.value + index} value={notification.value}>
                  {notification.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FormsSettingsForm;
