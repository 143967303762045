import React from 'react';
import Routes from './routes/Routes';
import {createTheme, ThemeProvider} from '@mui/material';
import Snackbars from './common/Snackbars/Snackbars';
import {lightModePalette, myBreakpoints, typographyProperties} from './helpers/constant/themeConstant';
import {setCookie} from './helpers/Cookie';


function App({history}) {
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: lightModePalette,
        typography: typographyProperties,
        breakpoints: myBreakpoints
      }),
    []
  );

  if (process.env.REACT_APP_PRODUCTION_MODE !== 'true') {
    // TODO set api access token in cookie for test
    setCookie(
      process.env.REACT_APP_ACCESS_TOKEN,
      'W6GSG6PQ6K5ZCQ1UQT5E',
      process.env.REACT_APP_ACCESS_TOKEN_VALIDITY
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Snackbars />
      <Routes history={history} />
    </ThemeProvider>
  );
}

export default App;
