import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import store from './state/store';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './assets/scss/main.scss';

window.renderFormBuilderV3Module = (containerId, history) => {
  ReactDOM.render(
    // <React.StrictMode>
    <>
      <Provider store={store}>
        <App history={history} />
      </Provider>
    </>,
    // </React.StrictMode>,
    document.getElementById(containerId)
  );
  serviceWorker.unregister();
};

window.unmountFormBuilderV3Module = containerId => {
  if (document.getElementById(containerId)) {
    ReactDOM.unmountComponentAtNode(document.getElementById(containerId));
  }
};

if (
  process.env.REACT_APP_PRODUCTION_MODE !== true &&
  document.getElementById('FormBuilderModuleV3-root')
) {
    document.getElementById('fbp_loading')?.remove();
  ReactDOM.render(
    // <React.StrictMode>
    <>
      <Provider store={store}>
        <App />
      </Provider>
    </>,
    // </React.StrictMode>,
    document.getElementById('FormBuilderModuleV3-root')
  );
  serviceWorker.unregister();
}